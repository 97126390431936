var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Nueva orden de trabajo",
            impedirClose: _vm.impedirClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-position": "left",
                "label-width": "130px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Fecha", prop: "fecha" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "dd/MM/yyyy",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "Seleccionar"
                    },
                    on: {
                      change: function($event) {
                        return _vm.seleccionFecha()
                      }
                    },
                    model: {
                      value: _vm.form.fecha,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "fecha", $$v)
                      },
                      expression: "form.fecha"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Provincia", prop: "area" } },
                [
                  _c("maca-select-box", {
                    ref: "selectBoxArea",
                    attrs: { url: _vm.urlArea, itemLabel: "descripcion" },
                    on: { change: _vm.seleccionarArea },
                    model: {
                      value: _vm.form.area,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "area", $$v)
                      },
                      expression: "form.area"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.area != null,
                      expression: "form.area != null"
                    }
                  ],
                  attrs: { label: "Localidad", prop: "subarea" }
                },
                [
                  _c("maca-select-box", {
                    ref: "selectBoxSubarea",
                    attrs: {
                      necesitaParams: true,
                      getParams: _vm.getParamsSubarea,
                      url: _vm.urlSubarea,
                      itemLabel: "descripcion"
                    },
                    on: {
                      change: function($event) {
                        return _vm.cargarReservasDiponibles()
                      }
                    },
                    model: {
                      value: _vm.form.subarea,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "subarea", $$v)
                      },
                      expression: "form.subarea"
                    }
                  })
                ],
                1
              ),
              _vm.form.subarea
                ? _c(
                    "div",
                    [
                      _c("h3", [_vm._v("Reservas confirmadas:")]),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.cargandoReservas,
                              expression: "cargandoReservas"
                            }
                          ],
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.reservasDiponibles }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "descripcionServicio",
                              label: "Servicio"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "name", label: "cliente" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            props.row.detalleClienteReserva
                                              .nombre
                                          ) + " ("
                                        )
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            props.row.detalleClienteReserva
                                              .telefono
                                          ) + ", "
                                        )
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            props.row.detalleClienteReserva
                                              .domicilio
                                          ) + ")"
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3136688001
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Confirmado",
                              width: "105",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(props) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-value": 1,
                                          "inactive-value": 0,
                                          disabled: props.row.bloqueado
                                            ? !_vm.$store.getters.tienePermiso(
                                                "M_USE_DES"
                                              )
                                            : !_vm.$store.getters.tienePermiso(
                                                "M_USE_BLO"
                                              )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.toogleConfirmar(
                                              props.row
                                            )
                                          }
                                        },
                                        model: {
                                          value: props.row.confirmado,
                                          callback: function($$v) {
                                            _vm.$set(
                                              props.row,
                                              "confirmado",
                                              $$v
                                            )
                                          },
                                          expression: "props.row.confirmado"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2064690283
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c("maca-boton-guardar", {
                    attrs: {
                      disabled:
                        !_vm.form.area ||
                        !_vm.form.subarea ||
                        !_vm.form.fecha ||
                        _vm.reservasDiponibles.length < 1,
                      onSubmit: _vm.onSubmit
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }