<template>
  <div>
    <el-card :body-style="{ padding: '25px' }">
      <div slot="header">
        <div style="margin-left: 0px">
          <div class="buscador3">
            <el-date-picker
              ref="datepicker"
              v-model="fecha"
              type="date"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              placeholder="Seleccionar fecha"
              @change="actualizarTablaFuncion()"
            ></el-date-picker>
          </div>
        </div>
        <el-button
          class="botonmasIcono"
          icon="el-icon-plus"
          type="primary"
          round
          style="margin-right: 0px"
          @click="$refs.modalNuevo.abrir()"
          >Nuevo</el-button
        >
      </div>

      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <div>
                <div>
                  <h4>Reservas</h4>
                  <div
                    v-for="(item, index) in props.row.detalleReservas"
                    :key="index"
                    style="display: flex; margin-bottom: 10px"
                  >
                    <div style="margin: auto; margin-left: 30px; width: 100%">
                      <el-row :gutter="10">
                        <el-col :span="8">
                          <span>
                            Horario:<b>
                              {{
                                $maca.common.formatearHora(
                                  item.detalleReserva.detalleTurnoTecnico
                                    .detalleTurno.horaInicio
                                )
                              }}</b
                            >
                            a
                          </span>
                          <span>
                            <b>{{
                              $maca.common.formatearHora(
                                item.detalleReserva.detalleTurnoTecnico
                                  .detalleTurno.horaFin
                              )
                            }}</b>
                          </span>
                        </el-col>
                        <el-col :span="8">
                          <div v-if="item.detalleReserva != null">
                            <span
                              v-if="item.detalleReserva.detalleCliente != null"
                            >
                              Para:
                              <b>{{
                                item.detalleReserva.detalleCliente.nombre
                              }}</b>
                            </span>
                            <span
                              v-if="item.detalleReserva.detalleCliente != null"
                            >
                              (<b>{{
                                item.detalleReserva.detalleCliente.telefono
                              }}</b
                              >,
                            </span>
                            <span
                              v-if="item.detalleReserva.detalleCliente != null"
                            >
                              <b>{{
                                item.detalleReserva.detalleCliente.domicilio
                              }}</b
                              >)
                            </span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <span>
                            Necesita:
                            <b>{{ item.detalleReserva.descripcionServicio }}</b>
                          </span>
                        </el-col>
                      </el-row>
                    </div>
                    <div
                      style="
                        margin: auto;
                        margin-left: 30px;
                        margin-right: 106px;
                      "
                    ></div>
                  </div>
                  <h4>Retiros</h4>
                  <div
                    v-for="(item, index) in props.row.detalleRetiros"
                    :key="index"
                    style="display: flex; margin-bottom: 10px"
                  >
                    <div style="margin: auto; margin-left: 30px; width: 100%">
                      <el-row :gutter="10">
                        <el-col :span="8">
                          <span>
                            Horario aprox. de retiro:<b>
                              {{
                                $maca.common.formatearHora(
                                  item.detalleRetiro.horaRetiroAprox
                                )
                              }}</b
                            >
                          </span>
                        </el-col>
                        <el-col :span="8">
                          <div v-if="item.detalleReserva != null">
                            <span
                              v-if="item.detalleReserva.detalleCliente != null"
                            >
                              Para:
                              <b>{{
                                item.detalleRetiro.detalleCliente.nombre
                              }}</b>
                            </span>
                            <span
                              v-if="item.detalleReserva.detalleCliente != null"
                            >
                              (<b>{{
                                item.detalleRetiro.detalleCliente.telefono
                              }}</b
                              >,
                            </span>
                            <span
                              v-if="item.detalleReserva.detalleCliente != null"
                            >
                              <b>{{
                                item.detalleRetiro.detalleCliente.domicilio
                              }}</b
                              >)
                            </span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <span>
                            Necesita:
                            <b>{{ item.detalleRetiro.descripcionServicio }}</b>
                          </span>
                        </el-col>
                      </el-row>
                    </div>
                    <div
                      style="
                        margin: auto;
                        margin-left: 30px;
                        margin-right: 106px;
                      "
                    ></div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column :min-width="120" label="Fecha">
            <template slot-scope="props">
              <span
                v-text="$maca.common.formatearFecha(props.row.fecha)"
              ></span>
            </template>
          </el-table-column>

          <el-table-column :min-width="120" label="Provincia">
            <template slot-scope="props">
              <div v-if="props.row.detalleReservas > 0">
                <div
                  v-if="
                    props.row.detalleReservas[0].detalleReserva
                      .detalleSubarea != null
                  "
                >
                  <span
                    v-if="
                      props.row.detalleReservas[0].detalleReserva.detalleSubarea
                        .detalleArea != null
                    "
                    v-text="
                      props.row.detalleReservas[0].detalleReserva.detalleSubarea
                        .detalleArea.descripcion
                    "
                  ></span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column :min-width="120" label="Localidad">
            <template slot-scope="props">
              <div v-if="props.row.detalleReservas.length > 0">
                <span
                  v-if="
                    props.row.detalleReservas[0].detalleReserva
                      .detalleSubarea != null
                  "
                  v-text="
                    props.row.detalleReservas[0].detalleReserva.detalleSubarea
                      .descripcion
                  "
                ></span>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                @click="eliminar(props.row.id)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "usuario",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: "/ordenTrabajo/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      fecha: null,
      filtroNombre: null,
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el usuario."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/ordenTrabajo/eliminar",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Orden de trabajo borrada con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleBloqueado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id };
      let respuestaApi = null;

      if (fila.bloqueado) {
        respuestaApi = await this.$maca.api.post("/user/bloquear", params);
      } else {
        respuestaApi = await this.$maca.api.post("/user/desbloquear", params);
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    actualizarTablaFuncion() {
      if (this.fecha != null) {
        this.paramsTabla = { fecha: this.fecha };
      } else {
        this.paramsTabla = {};
      }
      this.actualizarTabla = true;
    },
  },
  watch: {
    filtroNombre: function (n, o) {
      this.paramsTabla.nombre = this.filtroNombre;
      this.actualizarTabla = true;
    },
  },
};
</script>
