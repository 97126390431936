var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", { attrs: { "body-style": { padding: "25px" } } }, [
        _c(
          "div",
          { attrs: { slot: "header" }, slot: "header" },
          [
            _c("div", { staticStyle: { "margin-left": "0px" } }, [
              _c(
                "div",
                { staticClass: "buscador3" },
                [
                  _c("el-date-picker", {
                    ref: "datepicker",
                    attrs: {
                      type: "date",
                      format: "dd/MM/yyyy",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "Seleccionar fecha"
                    },
                    on: {
                      change: function($event) {
                        return _vm.actualizarTablaFuncion()
                      }
                    },
                    model: {
                      value: _vm.fecha,
                      callback: function($$v) {
                        _vm.fecha = $$v
                      },
                      expression: "fecha"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "el-button",
              {
                staticClass: "botonmasIcono",
                staticStyle: { "margin-right": "0px" },
                attrs: { icon: "el-icon-plus", type: "primary", round: "" },
                on: {
                  click: function($event) {
                    return _vm.$refs.modalNuevo.abrir()
                  }
                }
              },
              [_vm._v("Nuevo")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "maca-datatable",
              {
                attrs: {
                  url: _vm.urlTabla,
                  params: _vm.paramsTabla,
                  actualizar: _vm.actualizarTabla,
                  bloquear: _vm.bloquearTabla
                },
                on: {
                  "update:actualizar": function($event) {
                    _vm.actualizarTabla = $event
                  },
                  "update:bloquear": function($event) {
                    _vm.bloquearTabla = $event
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("div", [
                            _c(
                              "div",
                              [
                                _c("h4", [_vm._v("Reservas")]),
                                _vm._l(props.row.detalleReservas, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: {
                                        display: "flex",
                                        "margin-bottom": "10px"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            margin: "auto",
                                            "margin-left": "30px",
                                            width: "100%"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 10 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [
                                                  _c("span", [
                                                    _vm._v(" Horario:"),
                                                    _c("b", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$maca.common.formatearHora(
                                                              item
                                                                .detalleReserva
                                                                .detalleTurnoTecnico
                                                                .detalleTurno
                                                                .horaInicio
                                                            )
                                                          )
                                                      )
                                                    ]),
                                                    _vm._v(" a ")
                                                  ]),
                                                  _c("span", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$maca.common.formatearHora(
                                                            item.detalleReserva
                                                              .detalleTurnoTecnico
                                                              .detalleTurno
                                                              .horaFin
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [
                                                  item.detalleReserva != null
                                                    ? _c("div", [
                                                        item.detalleReserva
                                                          .detalleCliente !=
                                                        null
                                                          ? _c("span", [
                                                              _vm._v(" Para: "),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .detalleReserva
                                                                      .detalleCliente
                                                                      .nombre
                                                                  )
                                                                )
                                                              ])
                                                            ])
                                                          : _vm._e(),
                                                        item.detalleReserva
                                                          .detalleCliente !=
                                                        null
                                                          ? _c("span", [
                                                              _vm._v(" ("),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .detalleReserva
                                                                      .detalleCliente
                                                                      .telefono
                                                                  )
                                                                )
                                                              ]),
                                                              _vm._v(", ")
                                                            ])
                                                          : _vm._e(),
                                                        item.detalleReserva
                                                          .detalleCliente !=
                                                        null
                                                          ? _c("span", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .detalleReserva
                                                                      .detalleCliente
                                                                      .domicilio
                                                                  )
                                                                )
                                                              ]),
                                                              _vm._v(") ")
                                                            ])
                                                          : _vm._e()
                                                      ])
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [
                                                  _c("span", [
                                                    _vm._v(" Necesita: "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.detalleReserva
                                                            .descripcionServicio
                                                        )
                                                      )
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("div", {
                                        staticStyle: {
                                          margin: "auto",
                                          "margin-left": "30px",
                                          "margin-right": "106px"
                                        }
                                      })
                                    ]
                                  )
                                }),
                                _c("h4", [_vm._v("Retiros")]),
                                _vm._l(props.row.detalleRetiros, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: {
                                        display: "flex",
                                        "margin-bottom": "10px"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            margin: "auto",
                                            "margin-left": "30px",
                                            width: "100%"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 10 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " Horario aprox. de retiro:"
                                                    ),
                                                    _c("b", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$maca.common.formatearHora(
                                                              item.detalleRetiro
                                                                .horaRetiroAprox
                                                            )
                                                          )
                                                      )
                                                    ])
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [
                                                  item.detalleReserva != null
                                                    ? _c("div", [
                                                        item.detalleReserva
                                                          .detalleCliente !=
                                                        null
                                                          ? _c("span", [
                                                              _vm._v(" Para: "),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .detalleRetiro
                                                                      .detalleCliente
                                                                      .nombre
                                                                  )
                                                                )
                                                              ])
                                                            ])
                                                          : _vm._e(),
                                                        item.detalleReserva
                                                          .detalleCliente !=
                                                        null
                                                          ? _c("span", [
                                                              _vm._v(" ("),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .detalleRetiro
                                                                      .detalleCliente
                                                                      .telefono
                                                                  )
                                                                )
                                                              ]),
                                                              _vm._v(", ")
                                                            ])
                                                          : _vm._e(),
                                                        item.detalleReserva
                                                          .detalleCliente !=
                                                        null
                                                          ? _c("span", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .detalleRetiro
                                                                      .detalleCliente
                                                                      .domicilio
                                                                  )
                                                                )
                                                              ]),
                                                              _vm._v(") ")
                                                            ])
                                                          : _vm._e()
                                                      ])
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [
                                                  _c("span", [
                                                    _vm._v(" Necesita: "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.detalleRetiro
                                                            .descripcionServicio
                                                        )
                                                      )
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("div", {
                                        staticStyle: {
                                          margin: "auto",
                                          "margin-left": "30px",
                                          "margin-right": "106px"
                                        }
                                      })
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { "min-width": 120, label: "Fecha" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$maca.common.formatearFecha(props.row.fecha)
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { "min-width": 120, label: "Provincia" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          props.row.detalleReservas > 0
                            ? _c("div", [
                                props.row.detalleReservas[0].detalleReserva
                                  .detalleSubarea != null
                                  ? _c("div", [
                                      props.row.detalleReservas[0]
                                        .detalleReserva.detalleSubarea
                                        .detalleArea != null
                                        ? _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                props.row.detalleReservas[0]
                                                  .detalleReserva.detalleSubarea
                                                  .detalleArea.descripcion
                                              )
                                            }
                                          })
                                        : _vm._e()
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { "min-width": 120, label: "Localidad" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          props.row.detalleReservas.length > 0
                            ? _c("div", [
                                props.row.detalleReservas[0].detalleReserva
                                  .detalleSubarea != null
                                  ? _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          props.row.detalleReservas[0]
                                            .detalleReserva.detalleSubarea
                                            .descripcion
                                        )
                                      }
                                    })
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Borrar", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                circle: "",
                                disabled: !_vm.$store.getters.tienePermiso(
                                  "B_USE"
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.eliminar(props.row.id)
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-delete" })]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }