<template>
  <div>
    <maca-modal
      titulo="Nueva orden de trabajo"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        label-position="left"
        label-width="130px"
      >
        <el-form-item label="Fecha" prop="fecha">
          <el-date-picker
            v-model="form.fecha"
            type="date"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Seleccionar"
            @change="seleccionFecha()"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Provincia" prop="area">
          <maca-select-box
            ref="selectBoxArea"
            v-model="form.area"
            :url="urlArea"
            itemLabel="descripcion"
            @change="seleccionarArea"
          ></maca-select-box>
        </el-form-item>
        <el-form-item v-show="form.area != null" label="Localidad" prop="subarea">
          <maca-select-box
            ref="selectBoxSubarea"
            :necesitaParams="true"
            :getParams="getParamsSubarea"
            v-model="form.subarea"
            :url="urlSubarea"
            itemLabel="descripcion"
            @change="cargarReservasDiponibles()"
          ></maca-select-box>
        </el-form-item>
        <div v-if="form.subarea">
          <h3>Reservas confirmadas:</h3>
          <el-table
            :data="reservasDiponibles"
            style="width: 100%"
            v-loading="cargandoReservas"
          >
            <el-table-column prop="descripcionServicio" label="Servicio">
            </el-table-column>
            <el-table-column prop="name" label="cliente">
              <template slot-scope="props">
                <span>{{ props.row.detalleClienteReserva.nombre }} (</span>
                <span>{{ props.row.detalleClienteReserva.telefono }}, </span>
                <span>{{ props.row.detalleClienteReserva.domicilio }})</span>
              </template>
            </el-table-column>
            <el-table-column label="Confirmado" width="105" align="center">
              <template slot-scope="props">
                <el-switch
                  v-model="props.row.confirmado"
                  :active-value="1"
                  :inactive-value="0"
                  @change="toogleConfirmar(props.row)"
                  :disabled="
                    props.row.bloqueado
                      ? !$store.getters.tienePermiso('M_USE_DES')
                      : !$store.getters.tienePermiso('M_USE_BLO')
                  "
                ></el-switch>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-form-item>
          <maca-boton-guardar
            :disabled="
              !form.area ||
              !form.subarea ||
              !form.fecha ||
              reservasDiponibles.length < 1
            "
            :onSubmit="onSubmit"
          ></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    return {
      form: {
        fecha: null,
        area: null,
        subarea: null,
      },
      impedirClose: false,
      persona: null,
      urlArea: "/area/obtenerTodos",
      urlSubarea: "/subarea/obtenerTodos",
      reservasDiponibles: [],
      cargandoReservas: false,

      formRules: {
        area: [
          {
            required: true,
            message: "Por favor seleccione la provincia.",
            trigger: "change",
          },
        ],
        subarea: [
          {
            required: true,
            message: "Por favor seleccione la localidad.",
            trigger: "change",
          },
        ],
        fecha: [
          {
            required: true,
            message: "Por favor seleccione la fecha.",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "Por favor introduzca la contraseña de usuario.",
            trigger: "change",
          },
        ],
        rol: [
          {
            required: true,
            message: "Por favor introduzca el rol.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.persona = null;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    getParamsSubarea() {
      if (this.form.area) {
        let params = {
          areaID: this.form.area.id,
        };
        return params;
      }
      return {};
    },
    seleccionFecha() {
      (this.form.area = null),
        (this.form.subarea = null),
        (this.reservasDiponibles = []);
    },
    seleccionarArea() {
      this.form.subarea = null;
      if (this.form.area != null) {
        this.$refs.selectBoxSubarea.recargar();
      }
    },

    async cargarReservasDiponibles() {
      this.reservasDiponibles = [];
      let params = {
        sinPaginar: 1,
        confirmado: 1,
        fecha: this.form.fecha,
        subareaID: this.form.subarea.id,
      };
      this.cargandoReservas = true;
      // Hacer Post
      let url =
        "/reservas/obtenerTodos?sinPaginar=1&confirmado=1&fecha=" +
        this.form.fecha +
        "&subareaID=" +
        this.form.subarea.id;

      let respuestaApi = await this.$maca.api.get(
        "/reserva/obtenerTodos",
        params
      );
      //
      this.cargandoReservas = false;
      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.reservasDiponibles = respuestaApi.datos;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error al cargar reservas",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        fecha: this.form.fecha,
        subareaID: this.form.subarea.id,
      };

      let respuestaApi = await this.$maca.api.post(
        "/ordenTrabajo/crear",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Orden de trabajo creada con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },

    async toogleConfirmar(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id };
      let respuestaApi = null;

      if (fila.confirmado) {
        respuestaApi = await this.$maca.api.post("/reserva/confirmar", params);
      } else {
        respuestaApi = await this.$maca.api.post("/reserva/cancelar", params);
      }
      //

      this.cargarReservasDiponibles();

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>
